import React from 'react'

const Contactanos = () => {
  return (
    <div>
      contactanos
    </div>
  )
}

export default Contactanos
