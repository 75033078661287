import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import resumen from '../icons/resumen.svg'
import cupones from '../icons/cupones.svg'
import puntos from '../icons/puntos.svg'
import anfora from '../icons/anfora.svg'
import sorteos from '../icons/sorteos.svg'
import promociones from '../icons/promociones.svg'
import notificaciones from '../icons/notificaciones.svg'
import recompensas from '../icons/gift-box.svg'
import contactanos from '../icons/contactanos.svg'

export const SidebarNav = styled.aside`
  background: #fff;
  width: 230px;
  height: 100vh;
  display: flex;
  z-index: 12;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s all ease-in-out;
  z-index: 10;
  box-shadow:0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);

  @media screen and (max-width: 992px) {
    transition: 0.5s all ease-in-out;
    transform: translateX(-230px);
  }
`
export const SidebarLogo = styled.div`
  background-color: #fff;
  min-height: 60px;
  color: #000000b8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e1e5eb ;
`
export const LogoLink = styled(NavLink)`
  cursor:pointer;
  color: #4e4e4e;
  text-decoration: none;
  font-size: 18px;
`


export const SidebarWrap = styled.div`
  width: 100%;
  padding-top: 1rem;
  overflow-y: auto;
`

export const SidebarLink = styled(NavLink)`
  display: flex;
  color: #0a1033;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    box-shadow: inset 5px 0 0 #0A1033;
    background-color: #eaeaea;
    color: #0A1033;
    cursor: pointer;
  }
  &.active {
    box-shadow: inset 5px 0 0 #0A1033;
    background-color: #eaeaea;
    color: #0A1033;
    div{
      background-color: #0A1033;
    }
  }
`

export const SidebarLabel = styled.span`
  margin-left: 16px;
`

export const IconResumen = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${resumen}) no-repeat center;
  mask: url(${resumen}) no-repeat center;
`
export const IconCupones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${cupones}) no-repeat center;
  mask: url(${cupones}) no-repeat center;
`
export const IconPuntos = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${puntos}) no-repeat center;
  mask: url(${puntos}) no-repeat center;
`
export const IconAnfora = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`
export const IconSorteos = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${sorteos}) no-repeat center;
  mask: url(${sorteos}) no-repeat center;
`
export const IconPromociones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${promociones}) no-repeat center;
  mask: url(${promociones}) no-repeat center;
`
export const IconRecompensas = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${recompensas}) no-repeat center;
  mask: url(${recompensas}) no-repeat center;
`
export const IconNotificaciones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${notificaciones}) no-repeat center;
  mask: url(${notificaciones}) no-repeat center;
`
export const IconContactanos = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${contactanos}) no-repeat center;
  mask: url(${contactanos}) no-repeat center;
`