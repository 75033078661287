import React from 'react'
import { useHistory } from "react-router-dom"
import styled, { keyframes } from 'styled-components'
import ProgressBar from 'react-bootstrap/ProgressBar'

import cupones from '../icons/cupones.svg'
import { CustomButton2 } from '../generales/custombutton'


const gradient = keyframes`
	0% {
		background-position:100%;
	}

	100% {
		background-position:0%;
	}
`
export const CuponCard =styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width:100%;
  border-radius: 10px;
  background:white;
  padding: 1rem;
  box-shadow: inset 10px 0px 0px  ${p  => p.inputColor ? p.inputColor : '#531C99'};
  filter: drop-shadow(2px 4px 6px rgb(0 0 0 / 40%));
  transition: 0.5s all ease-in-out;
  color: #0A1033;
  margin-bottom: 1rem;
  background: #fff;
  ${'' /* background-size: 100% 51%;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 65px 0, rgba(255, 255, 224, 0) 0.5rem, #9C27B5 0.5em), radial-gradient(circle at 65px 100%, rgba(255, 255, 224, 0) 0.5rem, #9C27B5 0.5em);
  background-position: left top, right bottom; */}
  /*background: linear-gradient(90deg, #9C27B5 0%, #2C0E52 100%);*/
`

export const IconCuponesPrimary = styled.div`
  width:30px;
  height:32px;
  background-color: #fff;
  -webkit-mask: url(${cupones}) no-repeat center;
  mask: url(${cupones}) no-repeat center;
`

export const IconCuponesSecondary = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${cupones}) no-repeat center;
  mask: url(${cupones}) no-repeat center;
`

const NombreCupon =styled.div`
  display:flex;
  flex-direction: row;
  align-items:center;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
  text-align: end;

  @media screen and (max-width: 350px){
    transition: 0.5s all ease-in-out;
    font-size: 0.9rem;
  }
`
const CantidadCupon =styled.div`
  font-size:1rem;
  width:100%;
  text-align:end;
  transition: 0.5s all ease-in-out;
  span{
    transition: 0.5s all ease-in-out;
    font-size:0.9rem;
  }
  @media screen and (max-width: 350px){
    span{
      transition: 0.5s all ease-in-out;
      font-size: 0.8rem;
    }
  }
`
 export const CustomProgressBar =styled(ProgressBar)`
  background:#d9dbdd;
  box-shadow: 0px 2px 3px 0px rgb(90 97 105 / 40%);
  >div{
    background:linear-gradient(90deg,#D835FA,#531C99,#D835FA,#531C99);
    box-shadow: 0px 2px 3px 0px rgb(90 97 105 / 40%);
    background-size: 300% 100%;
	animation: ${gradient} 1s linear infinite;
  }
`

const Cupon = ({ nombre, cantidad, porcentaje, detalle,cuponColor }) => {

  const history = useHistory()

  function handleClick() {
    history.push("/main/anforas");
  }

  return (
    <CuponCard inputColor={cuponColor}>
      <NombreCupon> 
        <IconCuponesSecondary /> 
        <span style={{marginLeft:'0.3rem'}}>{nombre} </span>
      </NombreCupon>
      
      <CantidadCupon>
        <strong>{cantidad}</strong>
        <span> cupones disponibles</span> 
      </CantidadCupon>

      <div style={{width:'100%', margin:'0.5rem 0'}}>
        <CustomProgressBar animated now={porcentaje} label={porcentaje +'%'} />
      </div>

      {detalle && 
        <div style={{marginTop:'0.5rem'}}>
          <CustomButton2 size="sm" onClick={handleClick}>DEPOSITAR</CustomButton2>  
        </div>
      }
    </CuponCard>
  )
}

export default Cupon
