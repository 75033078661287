import React from 'react'
import { useHistory } from "react-router-dom"
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'
import dateFormat from 'dateformat'

import sorteos from '../icons/sorteos.svg'
import efectivo from '../icons/money.svg'
import regalo from '../icons/gift-box.svg'
import { CustomButton2 } from '../generales/custombutton'

const SorteoContainerDesktop =styled.div`
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 40%);
  background-clip: border-box;
  border-radius: 4px;
  margin-bottom:1rem;
  transition: 0.5s all ease-in-out;
  table{
    color: #0A1033 !important;
  }

  @media screen and (max-width: 768px){
    transition: 0.5s all ease-in-out;
    display: none;
  }
`

const SorteoContainerMobile =styled.div`
  transition: 0.5s all ease-in-out;
    display: none;
  @media screen and (max-width: 768px){
    transition: 0.5s all ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 40%);
    background-clip: border-box;
    border-radius: 10px;
    margin-bottom: 1rem;
    background: linear-gradient(179deg,#D835FA 0%,#531C99 65px);
  }
`

const TituloContainer =styled.div`
  padding: 1rem 0 0.5rem;
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  span{
    font-size:1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`

const InfoSorteo =styled.div`
  padding: 1rem 0.5rem;
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  color: #0A1033;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 40%);
`
const InfoPremio =styled.span`
  display:flex;
  flex-direction: row;
  align-items: center;
  
  strong{
    display:flex;
    flex-direction: row;
    align-items: center;
    text-transform:uppercase;
  }
  img{margin-left:0.4rem}
`
const BotonContainer =styled.div`
  padding: 0 0.5rem 1rem;
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  border-radius: 0 0 10px 10px;
`
export const IconSorteosSecondary = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${sorteos}) no-repeat center;
  mask: url(${sorteos}) no-repeat center;
`
export const IconEfectivo = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${efectivo}) no-repeat center;
  mask: url(${efectivo}) no-repeat center;
`
export const IconRegalo = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${regalo}) no-repeat center;
  mask: url(${regalo}) no-repeat center;
`

const Sorteo = ({ datasorteo }) => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const history = useHistory()

  function handleClick() {
    history.push("/main/anforas");
  }
  
  return (
    <>
      <SorteoContainerDesktop>
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th className='text-center'>Hora</th>
              <th>Promoción</th>
              <th className='text-center'>Premio</th>
              <th className='text-center'>Estado</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              datasorteo.map((item,i) => (
                <tr key={i}>
                  <td className='text-center'>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</td>
                  <td>
                    {item.kind === 'HOT_SEAT' ? 
                      <>
                        {salaInfo.salaId === 63 ? 'BIG WIN': 'HOT SEAT'}
                      </>
                      :
                      <>
                        {item.couponTypeName}  
                      </>
                    }
                  </td>
                  <td >
                    <div style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{marginRight:'0.3rem'}}>{item.amount + ' ' + item.premioTipo} </span>
                      {item.premioTipo === 'soles' ?
                        <IconEfectivo />
                        :
                        <IconRegalo />
                      }
                    </div>
                  </td>
                  <td className='text-center'>{item.locked ? ' REALIZADO':'PENDIENTE' }</td>
                  <td className='text-center'>
                      <CustomButton2 onClick={handleClick} style={{fontSize:'0.7rem'}} disabled={item.locked || item.kind === 'HOT_SEAT'}>PARTICIPAR</CustomButton2>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </SorteoContainerDesktop>
      <div>
        {
          datasorteo.map((item,i) => (
            <SorteoContainerMobile key={i}>
              <TituloContainer>
                <span>
                  {item.kind === 'HOT_SEAT' ? 
                    <>
                      {salaInfo.salaId === 63 ? 'BIG WIN': 'HOT SEAT'}
                    </>
                    :
                    <>
                      {item.couponTypeName}  
                    </>
                  }
                </span>
              </TituloContainer>
              <InfoSorteo>
                <span>Fecha: <strong>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</strong></span>
                <InfoPremio>Premio: 
                  <strong style={{marginRight:'0.3rem'}}>{item.amount + ' ' + item.premioTipo}</strong>
                  {item.premioTipo === 'soles' ?
                    <IconEfectivo />
                    :
                    <IconRegalo />
                  }
                </InfoPremio>
                <span>Estado: <strong>{item.locked ? ' REALIZADO':'PENDIENTE' }</strong></span>
              </InfoSorteo>
              <BotonContainer>
                <CustomButton2 size="sm" onClick={handleClick} disabled={item.locked || item.kind === 'HOT_SEAT'}>PARTICIPAR</CustomButton2>
              </BotonContainer>
              
            </SorteoContainerMobile>
            
          ))
        }
      </div>
    </>
  )
}

export default Sorteo
