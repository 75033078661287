import React, { useState, useEffect, useContext } from 'react'
import AuthContext from "../login/store/AuthContext"
import {logoutUser} from "../login/actions/authentication.action"
import useFetch from "../../hooks/useFetch"
import {Apis} from "../../util/Apis"

import { IconAnfora, IconCupones, IconPromociones, IconResumen, IconSorteos, CloseIcon, Icon, MenumobileContainer, MenumobileLabel, MenumobileLink, MenumobileWrapper, PerfilContainer, PerfilLink, IconPerfil, IconRecompensas } from './menumobileElements'
import { CustomButton2 } from '../generales/custombutton'

const MenuMobile = ({isOpen,toggle}) => {

  const {dispatch} = useContext(AuthContext)
  
  const {data: meData } = useFetch(Apis.CAPP + '/me')
  const [firstName, setFirstName] = useState(' -- ')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
    }
  }, [meData])

  return (
    <MenumobileContainer isOpen={isOpen} onClick={toggle} >
      
      <PerfilContainer>
        <PerfilLink to='/main/perfil' onClick={toggle}>
            <span style={{margin:"0 0.5rem",fontWeight:'bold',textTransform:'capitalize'}} >Bienvenido, {firstName}</span>
        </PerfilLink>
        <Icon onClick={toggle} >
          <CloseIcon />
        </Icon>
      </PerfilContainer>

      <MenumobileWrapper>
          {/* <MenumobileLink to='/main' onClick={toggle} exact={true}>
            <IconResumen />
            <MenumobileLabel>Resumen</MenumobileLabel>
          </MenumobileLink> */}
          <MenumobileLink to='/main' onClick={toggle} exact={true}>
            <IconCupones />
            <MenumobileLabel>Cupones</MenumobileLabel>
          </MenumobileLink>
          {/* <MenumobileLink to='/main/puntos' onClick={toggle}>
          <img src={puntos} alt='puntos' width='30px'/>
            <MenumobileLabel>Puntos</MenumobileLabel>
          </MenumobileLink> */}
          <MenumobileLink to='/main/anforas' onClick={toggle}>
            <IconAnfora />
            <MenumobileLabel>Ánforas</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/main/sorteos' onClick={toggle}>
            <IconSorteos />
            <MenumobileLabel>Sorteos</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/main/recompensas' onClick={toggle}>
            <IconRecompensas />
            <MenumobileLabel>Recompensas</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/main/promociones' onClick={toggle}>
            <IconPromociones />
            <MenumobileLabel>Promociones</MenumobileLabel>
          </MenumobileLink>
          {/* <MenumobileLink to='/main/notificaciones' onClick={toggle}>
          <img src={notificaciones} alt='notificaciones' width='30px'/>
            <MenumobileLabel>Notificaciones</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/main/contactanos' onClick={toggle}>
          <img src={contactanos} alt='contactanos' width='30px'/>
            <MenumobileLabel>Contactanos</MenumobileLabel>
          </MenumobileLink> */}
          <MenumobileLink to='/main/perfil' onClick={toggle}>
            <IconPerfil />
            <MenumobileLabel>Perfil</MenumobileLabel>
          </MenumobileLink>

          <CustomButton2 size='sm' onClick={() => logoutUser(dispatch)} style={{margin:'1rem'}}>CERRAR SESIÓN</CustomButton2>
      </MenumobileWrapper>
    </MenumobileContainer>
  )
}

export default MenuMobile
