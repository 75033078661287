export const Apis = Object.freeze({

  // BASE: 'http://localhost:9044',
  // OAUTH: 'http://localhost:9044',
  // CAPP: 'http://localhost:9078',

  // BASE: process.env.REACT_APP_BASE_URL,
  //OAUTH: process.env.REACT_APP_BASE_AUTH,
  //CAPP: process.env.REACT_APP_BASE_CAPP,

  BASE: 'https://api4.wgt.pe',
  OAUTH: 'https://accounts.wgt.pe',
  CAPP: 'https://api4.wgt.pe/xcustomerapp',
  //CAPP: 'http://localhost:9021',
  SORTEOS: 'https://api4.wgt.pe/xsorteos',

});
