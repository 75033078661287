import React from 'react'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'

const LoadingContainer =styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  background: rgb(255 255 255 / 50%);
  height: calc(100vh - 60px);
  width: calc(100% - 230px);
  position: absolute;
  top: 0;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 992px){
    transition: 0.5s all ease-in-out;
    width: 100%;
  }
`

const LoadingCard = () => {
  return (
    <LoadingContainer>
      <Spinner animation="border" variant="danger">
        <span className="visually-hidden">cargando...</span>
      </Spinner>
      <span className="text-muted mt-3">cargando...</span>
    </LoadingContainer>
  )
}

export default LoadingCard
