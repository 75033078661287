import React from 'react'
import styled from 'styled-components'

const CabeceraContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 40%);
  background-color: #fff;
  background-clip: border-box;
  border-radius: 10px;
  background: #fff;
  padding: 1rem;
  margin-top: 1rem;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.5s all ease-in-out;
  }
`
const CabeceraTitulo =styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size:0.8rem;
  justify-content: center;
  width:100%;
  color: #0A1033;
`
const InfoPuntosCantidad =styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:100%;
  color: #0A1033;
  text-transform:lowercase;
  strong{
    font-size:1.5rem;
    margin-right:0.3rem;
  }
`
const CabeceraImg = styled.div`
  border: 2px solid #0A1033;
  border-radius: 40px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  margin: 0.5rem 0;
`

 const CabeceraTemplate = ({nombreitem, presentacion, icono, cantidad}) => {
  return (
    <CabeceraContainer>
      <CabeceraTitulo>
        <span>{presentacion}</span>
      </CabeceraTitulo>
        {icono &&
          <CabeceraImg>
            {icono}
          </CabeceraImg>
        }
        {cantidad>=0 &&
          <InfoPuntosCantidad>
            <span><strong >{cantidad}</strong> {nombreitem}</span>
          </InfoPuntosCantidad>
        }
    </CabeceraContainer>
  )
}

export default CabeceraTemplate