import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import {FaTimes} from 'react-icons/fa'

import resumen from '../icons/resumen.svg'
import cupones from '../icons/cupones.svg'
import puntos from '../icons/puntos.svg'
import anfora from '../icons/anfora.svg'
import sorteos from '../icons/sorteos.svg'
import promociones from '../icons/promociones.svg'
import notificaciones from '../icons/notificaciones.svg'
import contactanos from '../icons/contactanos.svg'
import recompensas from '../icons/gift-box.svg'
import man from '../icons/man.svg'

export const MenumobileContainer =styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  overflow-y: auto;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`
export const PerfilContainer =styled.div`
  display: flex;
  flex-direction: row;
  background: #0A1033;
  padding: 0px 1rem;
  width: 100%;
  min-height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`

export const PerfilLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size:1rem;
  transition: 0.2s all ease-in-out;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  list-style: none;
`

export const CloseIcon =styled(FaTimes)`
  color: #fff;
`

export const Icon =styled.div`
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  transform: translate(-20%,0%);
`

export const MenumobileWrapper =styled.div`
  color: #0A1033;
  background: linear-gradient(180deg, #fff, transparent);
  width: 100%;
  padding: 1rem;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
`

export const MenumobileLink = styled(NavLink)`
  display: flex;
  color: #0A1033;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width:100%;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s all ease-in-out;
  &.active{
    box-shadow: inset 0 -5px #D835FA;
    background-color: #eaeaea;
    color: #0A1033;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;
    div{
      background-color: #0A1033;
    }
  }
  &:hover{
    box-shadow: inset 0 -5px #D835FA;
    background-color: #eaeaea;
    color: #0A1033;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;
  }
`
export const MenumobileLabel = styled.span`
  margin-left: 16px;
`

export const IconResumen = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${resumen}) no-repeat center;
  mask: url(${resumen}) no-repeat center;
`
export const IconCupones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${cupones}) no-repeat center;
  mask: url(${cupones}) no-repeat center;
`
export const IconPuntos = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${puntos}) no-repeat center;
  mask: url(${puntos}) no-repeat center;
`
export const IconAnfora = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`
export const IconSorteos = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${sorteos}) no-repeat center;
  mask: url(${sorteos}) no-repeat center;
`
export const IconPromociones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${promociones}) no-repeat center;
  mask: url(${promociones}) no-repeat center;
`
export const IconNotificaciones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${notificaciones}) no-repeat center;
  mask: url(${notificaciones}) no-repeat center;
`
export const IconRecompensas = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${recompensas}) no-repeat center;
  mask: url(${recompensas}) no-repeat center;
`

export const IconContactanos = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${contactanos}) no-repeat center;
  mask: url(${contactanos}) no-repeat center;
`

export const IconPerfil = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${man}) no-repeat center;
  mask: url(${man}) no-repeat center;
`
