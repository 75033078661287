import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import MenuMobile from '../components/menumobile'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import MainContainer from '../components/contenedor'

import Resumen from '../pages/resumen'
import Cupones from '../pages/cupones'
import Puntos from '../pages/puntos'
import Anforas from '../pages/anforas'
import DepositarCupones from '../pages/depositarCupones'
import Sorteos from '../pages/sorteos'
import Promociones from '../pages/promociones'
import Notificaciones from '../pages/notificaciones'
import Contactanos from '../pages/contactanos'
import Perfil from '../pages/perfil'
import Recompensas from './recompensas'

const Main = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <React.Fragment>
      <MenuMobile isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
      <Sidebar/>
      <MainContainer>
        <Switch>
          <Route path='/main/resumen' exact component={Resumen}/>
          <Route path='/main' exact component={Cupones}/>
          <Route path='/main/puntos' exact component={Puntos}/>
          <Route path='/main/anforas' exact component={Anforas}/>
          <Route path='/main/depositar-cupones/:id' exact component={DepositarCupones}/>
          <Route path='/main/sorteos' exact component={Sorteos}/>
          <Route path='/main/promociones' exact component={Promociones}/>
          <Route path='/main/notificaciones' exact component={Notificaciones}/>
          <Route path='/main/contactanos' exact component={Contactanos}/>
          <Route path='/main/recompensas' exact component={Recompensas}/>
          <Route path='/main/perfil' exact component={Perfil}/>
        </Switch>
      </MainContainer>
    </React.Fragment>
  )
}

export default Main
