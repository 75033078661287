import styled from 'styled-components'
import Button from 'react-bootstrap/Button'

export const CustomButton1 =styled(Button)`
  color: #0a1033;
  font-weight: 900;
  background-color: #ffffff;
  border-color: #0a1033;
  padding: 0.4rem 1.5rem !important;
  border-radius: 15px !important;
  &:hover{
    color: #0A1033;
    background-color: #b7b7b7;
    border-color: #b7b7b7;
  }

  &:disabled{
    background-color: #ffffff;
    border-color: #0a1033;
    pointer-events: none;
    opacity: .65;
    color: #0a1033;
  }
`

export const CustomButton2 =styled(Button)`
  color: #ffffff;
  font-weight: 900;
  background-color: #132392;
  border-color: #132392;
  padding: 0.4rem 1.5rem !important;
  border-radius: 15px !important;
  &:hover{
    color: #ffffff;
    background-color: #2c46f9;
    border-color: #2c46f9;
  }
  &:disabled{
    background-color: #132392;
    border-color: #132392;
    pointer-events: none;
    opacity: .65;
    color: #ffffff;
  }
`