import React,{ useState, useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import {Apis} from '../util/Apis'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Anfora, { IconAnforaSecondary } from '../components/templates/anforaTemplate'
import CabeceraTemplate from '../components/templates/cabeceraTemplate'
import LoadingCard from '../components/generales/loading'
import Noelements from '../components/generales/noelements'

const Anforas = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))

  const {data, loading} = useFetch(Apis.CAPP + '/anforas?salaId=' + salaInfo.salaId)

  const [anforasDisponibles, setAnforasDisponibles] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setAnforasDisponibles(data)
    }
  }, [data])


  return (
    <>
      {loading ? 
        <LoadingCard />
        : 
        <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}}>

          <Row>
            <Col xs={12} sm={12} md={12} >
              <CabeceraTemplate nombreitem='Ánforas' presentacion='Anforas disponibles:'
              icono={ <IconAnforaSecondary style={{width:'40px', height:'40px'}}/>} 
              cantidad={anforasDisponibles.length} />
            </Col>
          </Row>

          <Row style={{marginTop:'2rem'}} >
            {anforasDisponibles.length > 0 ?
              <>
                {anforasDisponibles.map((item, i) =>
                  <Col  xs={12} sm={6} md={6} key={i}>
                    <Anfora nombre={item.name} depositados={item.quantity} disponibles={item.couponsAvailable} id={item.id} anforaColor={item.couponTypeColor} />
                  </Col>
                )}
              </>
              :
              <Col  xs={12} sm={12} md={12}>
                <Noelements titulo='No hay ánforas disponibles en este momento' subtitulo='Vuelva a intentarlo más tarde' />
              </Col>
            }
          </Row>

        </Container>
      }
    </>
  )
}

export default Anforas
