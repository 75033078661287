import React, {useState,useEffect} from 'react'
import styled from 'styled-components'
import { Col, Container, Form, Row } from 'react-bootstrap'
import imgsPublicidad from '../components/data/publicidad.json'
import Noelements from '../components/generales/noelements'
import { ContenedorHeader } from '../components/generales/card'
import { CustomButton2 } from '../components/generales/custombutton'

import logowsp from '../components/icons/whatsapp.svg'

import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

SwiperCore.use([Pagination,Autoplay]);

export const IconWsp = styled.div`
  width:20px;
  height:20px;
  background-color: #fff;
  -webkit-mask: url(${logowsp}) no-repeat center;
  mask: url(${logowsp}) no-repeat center;
`

const PromoImg = styled.img`
  box-shadow: 0px 0 10px black;
  border-radius: 5px;
  margin-bottom: 1rem;
`

const PromocionesForm = styled.form`
  margin: 2rem 0px 1rem;
  width: 100%;
  text-align: center;
`

const Promociones = () => {


  const [sala, setSala] = useState([]);

  const [mensaje, setMensaje] = useState('Hola, quisiera saber más sobre las promociones!');

  useEffect(() => {
    let checkSala = localStorage.getItem("nombreSala")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
    }
  }, [])

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const result = imgsPublicidad.filter(item => item.salaId === salaInfo.salaId);

  const carousel= false

  const fullMensaje = sala.whatsapp + mensaje

  return (
    <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >
      <Row>
        <Col xs={12} sm={12} md={12}>
          <ContenedorHeader style={{marginBottom:'1.5rem'}}>
            <img src={`/${sala.path}`} alt='Casino' height='80px' />

            <PromocionesForm>
              <span style={{fontSize:'0.9rem'}}>Consulte a nuestro asesores sobre nuestras promociones: </span>
              <Form.Control as="textarea" rows={3} placeholder="Hola!"
                style={{marginTop:'0.5rem'}}
                value={mensaje}
                onChange={(e) =>
                    setMensaje(e.target.value)
                  }
                />
            </PromocionesForm>

            <CustomButton2 href={fullMensaje} target="_blank" rel="noopener noreferrer">
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <IconWsp />
                <span style={{marginLeft:'0.3rem'}}>PREGUNTAR</span>
              </div>
            </CustomButton2>

          </ContenedorHeader>
        </Col>

        { result[0].publicidad.length <= 0 ?
          <Col xs={12} sm={12} md={12} style={{marginTop:'2rem'}}>
            <Noelements titulo="No hay promociones disponibles en este momento" subtitulo="Vuelva a consultar mas tarde"/>
          </Col>
          :
          <>
          {carousel ?
            <Col xs={12} sm={12} md={12} style={{marginTop:'2rem'}}>
              <Swiper loop={true}
                autoplay={{
                  "delay": 2500,
                  "disableOnInteraction": false
                }}
                slidesPerView={1}
                spaceBetween={15}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                initialSlide={1}
              >
                {result[0].publicidad.map((i) => {
                  return <SwiperSlide key={i}><PromoImg src={i} alt="img" style={{margin:'0.5rem',width:'calc(100% - 1rem)'}}/></SwiperSlide>
                })}
              </Swiper>
            </Col>
            :
            <>
              {result[0].publicidad.map((item, i) =>
                <Col xs={12} sm={12} md={6}  key={i}>
                  <PromoImg src={item} width='100%' alt="promo"/>
                </Col>
              )}
            </> 
          }
          </>
        }
      </Row>
    </Container>

  )
}

export default Promociones
