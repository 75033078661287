import React, { useState, useEffect, useContext } from 'react'
import AuthContext from "../login/store/AuthContext"
import {logoutUser} from "../login/actions/authentication.action"
import useFetch from '../../hooks/useFetch'
import {Apis} from "../../util/Apis"
import { FaBars } from 'react-icons/fa'
import { IconNotificaciones, IconPerfil, MobileIcon, Nav, NavbarContainer, NavItem, NavLinks, NavMenu } from './navbarElements'

import { CustomButton1 } from '../generales/custombutton'


const Navbar = ({ toggle }) => {


  const {dispatch} = useContext(AuthContext)

  const {data: meData } = useFetch(Apis.CAPP + '/me')
  const [firstName, setFirstName] = useState(' -- ')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
    }
  }, [meData])

  return (
    <>
      <Nav>
        <NavbarContainer>
          <span style={{margin:"0 0.5rem",fontWeight:'bold',textTransform:'capitalize'}} >Bienvenido, {firstName}</span>
          <NavMenu>
            <NavItem>
              <NavLinks to='/main/notificaciones'>
                <IconNotificaciones />
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='/main/perfil'>
                <IconPerfil />
                  <span style={{margin:"0 0.5rem",fontWeight:'bold'}} >Bienvenido, {firstName}</span>
              </NavLinks>
            </NavItem>
            <NavItem>
              <CustomButton1 size="sm" onClick={() => logoutUser(dispatch)} >CERRAR SESIÓN</CustomButton1>
            </NavItem>
            <MobileIcon onClick={toggle} >
              <FaBars />
            </MobileIcon>
            
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
