import React, {useContext, useEffect, useState} from 'react'
import {Redirect, useLocation} from "react-router";
import {SET_CURRENT_USER} from "../components/login/actions/authentication.action";
import jwt_decode from "jwt-decode";
import AuthContext from "../components/login/store/AuthContext";

const nombreSala = '{"name":"Pachanga Independencia","path":"./salas/salapachangaind.png","salaId":62,"company":"Dreams Corporation S.A.C.","companyCorto":"Dreams","privacidad":"/politicaPrivacidad/privacidadPachangaInd.pdf","promopage":"https://promosfiestacasino.app.exur.com/","facebook":"https://www.facebook.com/CasinoPachangaIndDreams","instagram":null,"whatsapp":"https://api.whatsapp.com/send?phone=51970557339&text=Hola!%20Vi%20las%20promociones%20de%20Pachanga%20y%20tengo%20una%20consulta%20%3A)","auth":"pachangasb"}'

const Bridge = () => {

  const {stateUser, dispatch} = useContext(AuthContext);
  let location = useLocation()

  useEffect(() => {
    sendIdToken()
  }, [])

  function sendIdToken() {
    const params = getUrlParams()
    const idToken = params.get("#id_token")

    //const authHeader = "Bearer " + idToken
    socialUserExist(idToken)
  }

  function getUrlParams() {
    let arr = new Map();
    location.hash.split('&').map((par, index) => {
      arr.set(par.split('=')[0], par.split('=')[1]);
    })
    return arr
  }

  const socialUserExist = (token) => {
    localStorage.setItem("auth", token);
    localStorage.setItem("nombreSala", nombreSala);
    const decodedToken = jwt_decode(token);
    dispatch(setCurrentUser(decodedToken));
  }

  const referer = '/main'
  const isLoggedIn = stateUser.isAuthenticated

  if (isLoggedIn) {
    console.log('is logged ... redirecting')
    return <Redirect to={referer}/>
  }

  return (
    <div>
      Cargando...
    </div>
  )
}

export default Bridge

export const setCurrentUser = decodedToken => {//si se loguea , setear datos del usuario
  return {
    type: SET_CURRENT_USER,
    payload: decodedToken
  };
};
