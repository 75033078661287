import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"
import {useParams} from 'react-router'
import axios from 'axios'
import {useHistory} from "react-router-dom"
import {useForm} from "react-hook-form"

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CustomButton1, CustomButton2 } from '../components/generales/custombutton'
import LoadingCard from '../components/generales/loading'
import { CustomPanel, PanelBody, PanelHeader } from '../components/generales/panel'

const Depositados =styled.div`
  display: flex;
  font-size: 0.9rem;
  color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight:normal;
  span{
    transition: 0.5s all ease-in-out;
    margin-left:0.3rem;
  }
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px){
    transition: 0.5s all ease-in-out;
    font-size: 0.8rem;
  }
`

const Disponibles =styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:1.1rem;
  strong{
    margin-right:0.3rem;
  }
`
const CustomForm =styled.form`
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`


const ContenedorButton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 0.5s all ease-in-out;
  button{
    margin-bottom:0.5rem;
  }
`

const TextError = styled.span`
  margin: 10px 0px 20px;
  font-weight: normal;
  color: darkred;
  text-align: center;
  font-size: 14px;
`
const ContenedorInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top:0.5rem;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 425px){
    flex-direction: column;
    transition: 0.5s all ease-in-out;
  }
`
const LabelInput = styled.label`
  margin-right:0.5rem;
  white-space: nowrap;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 425px){
    transition: 0.5s all ease-in-out;
    font-size: 0.9rem;
    margin-bottom:0.5rem;
    white-space: normal;
  }
`

const DepositarCupones = () => {

  const history = useHistory();
  const {id} = useParams()
  const {salaId} = JSON.parse(localStorage.getItem('nombreSala'))

  const {register, handleSubmit, errors} = useForm();

  const [couponTypeId, setCouponTypeId] = useState(0)
  const [infoAnforas, setInfoAnforas] = useState([])
  const [error, setError] = useState('')
  const [loadingPost, setLoadingPost] = useState(false)

  
  const {data,loading } = useFetch(Apis.CAPP + '/anforas/' + id+'?salaId='+salaId)

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setInfoAnforas(data)
      setCouponTypeId(data.couponTypeId)
    }
  }, [data])

  const participarSorteos = (params) => {
    setLoadingPost(true)
    setError('')
    params.anforaId = id
    params.couponTypeId = couponTypeId
    params.salaId = salaId

    axios.post(Apis.CAPP + '/sorteos/play', params).then(res => {
      history.push({pathname: '/main/anforas'});
      setLoadingPost(false)
    }).catch(err => {
      setError(err.response.data.message)
      setLoadingPost(false)
    })
  }

  function goToAnfora() {
    history.push("/main/anforas");
  }

  return (
    <>
      {loading ? 
        <LoadingCard />
        : 
        <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >

          <Row>
            <Col xs={12} sm={12} md={12} >
            <CustomPanel>

              <PanelHeader>
                <span style={{fontSize:'1.2rem',fontWeight:'bold'}}> {infoAnforas.name} </span>
                <Depositados>
                  <strong>{infoAnforas.quantity}</strong>
                  <span>cupones depositados</span>
                </Depositados>
              </PanelHeader>

              <PanelBody>
                
                <Disponibles>
                  <strong>{infoAnforas.couponsAvailable}</strong>
                  <span>cupones disponibles</span>
                </Disponibles>

                <CustomForm>
                  <ContenedorInput>
                    <LabelInput>Ingrese un numero de cupones: </LabelInput>
                    <Form.Control type="number" placeholder="0"
                      style={{minWidth:'100px',maxWidth:'150px', textAlign:'center'}} autoFocus autoComplete="off"
                      required
                      name={"quantity"}
                      ref={register({
                          required: "Ingrese una cantidad",
                          min: {value: 1, message: "Debe ingresar una cantidad mayor a 0"},
                          max: {value: 1000, message: "Debe ingresar una cantidad menor a 1000"}
                        })}
                    />
                  </ContenedorInput>
                    
                  <TextError>{errors.quantity?.message}</TextError>

                  <ContenedorButton>
                    <CustomButton1 size='sm' onClick={goToAnfora}>REGRESAR</CustomButton1>
                    <CustomButton2 size='sm' onClick={handleSubmit(participarSorteos)} className='mx-2' disabled={loadingPost}>
                    {loadingPost ? 'CARGANDO' : 'DEPOSITAR'}</CustomButton2>
                  </ContenedorButton>

                  <TextError>{error}</TextError>
                </CustomForm>

              </PanelBody>
            </CustomPanel>
              
            </Col>
          </Row>

          
        </Container>
      }
    </>
  )
}

export default DepositarCupones
