import React,{ useState, useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cupon, { IconCuponesSecondary } from '../components/templates/cuponTemplate'

import CabeceraTemplate from '../components/templates/cabeceraTemplate'
import LoadingCard from '../components/generales/loading'
import Noelements from '../components/generales/noelements'


const Cupones = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))

  const {data , loading} = useFetch(Apis.CAPP + '/point-coupons?salaId=' + salaInfo.salaId)

  const [cuponesCliente, setCuponesCliente] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setCuponesCliente(data.coupons)
    }
  }, [data])
  
  return (
    <>
      {loading ? 
        <LoadingCard />
        : 
        <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >

          <Row>
            <Col xs={12} sm={12} md={12} >
              <CabeceraTemplate nombreitem='Cupones' presentacion='Cupones vigentes:'
              icono={ <IconCuponesSecondary style={{width:'40px', height:'40px'}} />} 
              cantidad={cuponesCliente.length} />
            </Col>
          </Row>

          <Row style={{marginTop:'2rem'}} >
            {cuponesCliente.length > 0 ?
              <>
                {cuponesCliente.map((item, i) =>
                  <Col  xs={12} sm={6} md={6} key={i}>
                    <Cupon nombre={item.couponName} cantidad={item.available} porcentaje={item.percentage} cuponColor={item.hexColor} detalle/>
                  </Col>
                )}
              </>
              :
              <Col  xs={12} sm={12} md={12}>
                <Noelements titulo='No hay cupones disponibles en este momento' subtitulo='Vuelva a intentarlo más tarde' />
              </Col>
            }
          </Row>

        </Container>
      
      }
    </>  
  )
}

export default Cupones
