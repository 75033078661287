import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import axios from "axios"
import {Apis} from "../util/Apis"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CabeceraImg } from './perfil'

import recompensa from '../components/icons/gift-box.svg'
import { CustomProgressBar } from '../components/templates/cuponTemplate'
import { ContenedorHeader } from '../components/generales/card'
import { CustomPanel, PanelBody, PanelHeader } from '../components/generales/panel'
import Noelements from '../components/generales/noelements'
import LoadingCard from '../components/generales/loading'

const RecompensaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 40%);
  background-color: #fff;
  background-clip: border-box;
  border-radius: 10px;
  background: #fff;
  color: #0A1033;
  padding: 1rem;
  margin-top:1rem;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.5s all ease-in-out;
  }
`

const DescripcionRecompensa = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TituloRecompensa = styled.span`
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
`
const TipoRecompensa = styled.span`
  font-size: 1.4rem;
  line-height: 1;
`
const ProgressBarContainer = styled.div`
  width: 100%;
  margin-top:1rem;
  text-align:center;
  span{
    font-size:14px;
    font-weight: bold;
  }
`

const IconRecompensa = styled.div`
  width:58px;
  height:62px;
  background-color: #0A1033;
  -webkit-mask: url(${recompensa}) no-repeat center;
  mask: url(${recompensa}) no-repeat center;
`

const RecompensaTitulo = styled.span`
  text-align:center;
  font-weight:bold;
  font-size: 1rem;
  margin-top: 1rem;
`

const TablaHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  text-align: center;
  border-block-end: 1px solid rgba(0, 0, 0, 0.133);
  padding: 0.3rem 0px;
`
const NivelRecompensa = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  border-block-end: 1px solid rgba(0, 0, 0, 0.133);
  padding: 0.3rem 0px;
  font-size: 14px;
`
const Recompensas = () => {

  const userAuth = localStorage.getItem('auth')
  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))
  const [puntosSemanales, setPuntosSemanales] = useState(0)
  const [tablaRecompensas, setTablaRecompensas] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    generarRecompensas()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  const config = {
    headers: { Authorization: `Bearer ${userAuth}` }
  };
  
  const generarRecompensas = () => {
    setLoading(true)
    axios.get(Apis.CAPP + '/rewards?salaId=' + salaInfo.salaId , config )
    .then(res => {
      setPuntosSemanales(res.data.weeklyPoints)
      setTablaRecompensas(res.data.rewards)
      setLoading(false)
    }).catch(err => {
      console.log('Error: ' + err.message)
      setError(true)
      setLoading(false)
    })
  }

  const pruebaB = [{
    min: 0,
    max: Math.min(...tablaRecompensas.map(item => item.min)) -1,
    description: "0"
    }
  ]
  
  const pruebaC =tablaRecompensas.concat(pruebaB);

  return (
    <>
    {loading ? 
        <LoadingCard />
        :
      <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >

        <Row>
          <Col xs={12} sm={12} md={12} >

            <ContenedorHeader>
              <CabeceraImg>
                <IconRecompensa />
              </CabeceraImg>
              <RecompensaTitulo>RECOMPENSA SEMANAL</RecompensaTitulo>
            </ContenedorHeader>
            
            {tablaRecompensas.length>0 ?
              <>
              <RecompensaContainer>

                <DescripcionRecompensa>
                  <span>recompensa actual:</span>
                  <TituloRecompensa>
                    {pruebaC
                      .filter(item => (item.min <= puntosSemanales && item.max >= puntosSemanales))
                      .map(item => item.description) 
                    }
                  </TituloRecompensa>
                  <TipoRecompensa>SOLES</TipoRecompensa>
                  
                </DescripcionRecompensa>

                <ProgressBarContainer>
                  <CustomProgressBar style={{height:'2rem',fontSize:'16px',marginBottom:'1rem'}} animated 
                  now={puntosSemanales} 
                  label={puntosSemanales +' puntos'}
                  min={
                    Math.max(...pruebaC.filter(item => (item.min <= puntosSemanales)).map(item => item.min))
                  }
                  max={
                    Math.min(...pruebaC.filter(item => item.max >= puntosSemanales).map(item => item.max))
                  } />
                  <span>te faltan {Math.max(...pruebaC.filter(item => (item.min <= puntosSemanales)).map(item => item.max)) - puntosSemanales + 1} punto(s) para acceder a la siguiente recompensa
                  </span>
                </ProgressBarContainer>

              </RecompensaContainer>

              <CustomPanel>
                <PanelHeader>
                  <span>TABLA DE RECOMPENSAS</span>
                </PanelHeader>
                <PanelBody>
                    <TablaHeader>
                      <div style={{width:'50%'}} >PUNTOS</div>
                      <div style={{width:'50%'}} >RECOMPENSA</div>
                    </TablaHeader>
                    {tablaRecompensas.map((item, i) =>
                      <NivelRecompensa key={i}>
                        <div style={{width:'50%'}}><span><strong>{item.min}</strong> a <strong>{item.max}</strong></span></div>
                        <div style={{width:'50%'}}>{item.description} SOLES</div>
                      </NivelRecompensa>
                    )}
                </PanelBody>
              </CustomPanel>
            </>
            :
              <Noelements titulo='No hay recompensas disponibles en este momento' subtitulo='Vuelva a intentarlo más tarde' />
            }
          </Col>
        </Row>
      </Container>
    }
    </>
  )
}

export default Recompensas
