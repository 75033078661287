import React from 'react'
import { useHistory } from "react-router-dom"
import styled from 'styled-components'

import anfora from '../icons/anfora.svg'
import { CustomButton2 } from '../generales/custombutton'

export const IconAnforaPrimary = styled.div`
  width:30px;
  height:32px;
  background-color: #fff;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`

export const IconAnforaSecondary = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`

/*contenedor */
const AnforaCard =styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-radius: 10px;
  background:white;
  box-shadow: inset 10px 0px 0px  ${p  => p.inputColor ? p.inputColor : '#531C99'};
  filter: drop-shadow(2px 4px 6px rgb(0 0 0 / 40%));
  transition: 0.5s all ease-in-out;
  color: #0A1033;
  margin-bottom:1rem;
`

/* lado derecho - cuerpo */
const CuerpoAnfora =styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding:1rem;
`
const NombreAnfora =styled.span`
  display:flex;
  flex-direction: row;
  align-items:center;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;
  text-align: end;

  @media screen and (max-width: 350px){
    transition: 0.5s all ease-in-out;
    font-size: 0.9rem;
  }
`
const CuponesDepositados =styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  margin-top:0.5rem;
  strong{
    transition: 0.5s all ease-in-out;
    margin:0 0.3rem;
  }
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px){
    transition: 0.5s all ease-in-out;
    font-size: 0.8rem;
  }
`

/* lado derecho - footer contenedor */
const FooterAnfora =styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: calc(100% - 10px);
  padding: 1rem;
  border-top: 1px solid #c8c8c8;
  background: #e9e9e9;
  border-radius: 0 0 10px 10px;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 768px){
    transition: 0.5s all ease-in-out;
    flex-direction: column;
  }
`
/* lado derecho - footer contenido */
const CuponesDisponibles =styled.div`
  display:flex;
  font-size: 1rem;
  flex-direction: row;
  align-items: flex-end;
  transition: 0.5s all ease-in-out;
  span{
    transition: 0.5s all ease-in-out;
    margin-left:0.3rem;
  }

  @media screen and (max-width: 768px){
    transition: 0.5s all ease-in-out;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 350px){
    transition: 0.5s all ease-in-out;
    font-size: 0.9rem;
  }
`

const Anfora = ({nombre,disponibles,depositados,id,anforaColor}) => {

  const history = useHistory()

  function goToDepositarCupones() {
    history.push("/main/depositar-cupones/" + id);
  }
  
  return (
      <AnforaCard inputColor={anforaColor}>
        <CuerpoAnfora>
          <NombreAnfora> 
            <IconAnforaSecondary />
            <span style={{marginLeft:'0.3rem'}}>{nombre} </span>
          </NombreAnfora>
          <CuponesDepositados className='text-muted'>
            { depositados > 0 ? 
              <>
                <span>Has depositado</span><strong>{depositados}</strong><span>cupon(es)</span>
              </> 
            :
              <span>Aún no has depositado cupones</span>
            }
            
          </CuponesDepositados>
        </CuerpoAnfora>

          <FooterAnfora>
            <CuponesDisponibles>
              <strong>{disponibles}</strong>
              <span>cupones disponibles</span>
            </CuponesDisponibles>
            <CustomButton2 size="sm" onClick={goToDepositarCupones} disabled={disponibles<=0}>DEPOSITAR</CustomButton2>  
          </FooterAnfora> 

      </AnforaCard>
  )
}

export default Anfora
