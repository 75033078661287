import React,{ useState, useEffect } from 'react'
import styled from 'styled-components'
import { Col, Container, Form, Row } from 'react-bootstrap'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import man from '../components/icons/man.svg'
import { CustomButton2 } from '../components/generales/custombutton'
import LoadingCard from '../components/generales/loading'
import { ContenedorHeader } from '../components/generales/card'

export const IconProfile = styled.div`
  width:60px;
  height:64px;
  background-color: #0A1033;
  -webkit-mask: url(${man}) no-repeat center;
  mask: url(${man}) no-repeat center;
`

export const CabeceraImg = styled.div`
  border: 2px solid #0A1033;
  border-radius: 40px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
`

const CabeceraNombre = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top:1rem;
`

const PerfilContainer =styled.div`
  transition: 0.5s all ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 40%);
  background-clip: border-box;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: linear-gradient(179deg,#D835FA 0%,#531C99 65px);
`

export const TituloContainer =styled.div`
  padding: 1rem 0;
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  span{
    font-size:1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`

export const FormLabel = styled.label`
  margin-bottom: 0.3rem;
  margin-top:1rem;
`

const InfoPerfil =styled.div`
  padding: 1rem;
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  color: #0A1033;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 40%);
`

const BotonContainer =styled.div`
  padding: 0 0.5rem 1rem;
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  border-radius: 0 0 10px 10px;
`


const Perfil = () => {

  const {data: meData, loading} = useFetch(Apis.CAPP + '/me')

  const [document, setDocument] = useState('')
  const [firstName, setFirstName] = useState(' -- ')
  const [lastName, setLastName] = useState(' -- ')
  const [password, setPassword] = useState('123456')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
      setLastName(meData.lastName)
      setDocument(meData.document)
    }
  }, [meData])

  return (
    <>
      {loading ? 
        <LoadingCard />
        : 
        <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >
          <Row>
            <Col xs={12} sm={12} md={12} >
              <ContenedorHeader>
                <CabeceraImg>
                  <IconProfile />
                </CabeceraImg>
                  <CabeceraNombre>
                    <span style={{textTransform:'capitalize'}} > {lastName + ', '}</span>
                    <span style={{marginLeft:"0.3rem",fontWeight:'bold',textTransform:'capitalize'}} >{firstName}</span>
                  </CabeceraNombre> 
              </ContenedorHeader>
            </Col>
          </Row>
            
          <Row  style={{marginTop:'2rem'}} >
            <Col>
              <PerfilContainer >
                <TituloContainer>
                  <span>Datos de usuario</span>
                </TituloContainer>
                <InfoPerfil>
                  <FormLabel>Nombres</FormLabel>
                  <Form.Control 
                    type="text"
                    id="nombres"
                    value={firstName}
                    onChange={(e) =>
                      setFirstName(e.target.value)
                    }
                  />
                  <FormLabel>Apellidos</FormLabel>
                  <Form.Control 
                    type="text"
                    id="apellidos"
                    value={lastName}
                    onChange={(e) =>
                      setLastName(e.target.value)
                    }
                  />
                  <FormLabel>Docuento</FormLabel>
                  <Form.Control disabled
                    type="text"
                    id="documento"
                    value={document}
                  />

                  <FormLabel>Contraseña</FormLabel>
                  <Form.Control disabled
                    type="password"
                    id="contraseña"
                    value={password}
                    onChange={(e) =>
                      setPassword(e.target.value)
                    }
                  />
                </InfoPerfil>
                <BotonContainer>
                  <CustomButton2 size="sm" disabled>GUARDAR CAMBIOS</CustomButton2>
                </BotonContainer>
                
              </PerfilContainer>
            </Col>
          </Row> 
        </Container>
      }
    </>  
  )
}

export default Perfil
