import styled, { keyframes } from 'styled-components'

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

export const LoginContainer =styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(-45deg, #132392, #d835fa, #1a6cc7, #531c99);
	background-size: 400% 400%;
	animation: ${gradient} 13s ease infinite;
`

export const CardLogin =styled.div`
  width: ${({ isSelect }) => (isSelect ? '400px' : '570px')};
  min-height: calc(100vh - 2rem);
  background: rgb(255 255 255 / 98%);
  border-radius: 4px;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 8px;
  transition: 0.3s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.3s all ease-in-out;
    width: 100%;
    padding: 1rem 0.5rem;
  }
`

export const ContenidoLogin =styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`

export const SeleccioneSala = styled.span`
 color: #005AC8;
 width: 100%;
 display: flex;
 justify-content: center;
 font-size:14px;
 text-align:center;
`

export const SalaContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5rem;
`

export const SalaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  height: 140px;
  justify-content: center;
  border-radius: 4px;
  background-color:#fff;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 8px;
  cursor: pointer;
  margin: 0.5rem;
  transition: 0.3s all ease-in-out;

  &:hover{
    transition: 0.3s all ease-in-out;
    transform: scale(1.1);
  }

  @media screen and (max-width: 425px){
    transition: 0.3s all ease-in-out;
    min-width: calc(50% - 1rem);
    height: 130px;
    img{
      max-width: 90%;
    }
  }
`

export const NombreSala = styled.span`
  text-transform: uppercase;
  color: #132392;
  font-weight: bold;
  padding: 0.5rem 0;
  font-size: 1.1rem;
  text-align:center;
`

export const SelectSala = styled.span`
  cursor: pointer;
  color: #531c99;
  margin-bottom: 0.5rem;
  font-size:0.9rem;
  text-align:center;
`

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 0 1rem 1rem;
`

export const FormLabel = styled.label`
  margin-bottom: 0.3rem;
  margin-top:1rem;
`

export const TextError = styled.span`
  text-align: center;
  color: #531c99;
  font-weight: bold;
  margin: 0px;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContentCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #2D3142;
  label{
    margin-bottom: 0.5rem;
  }
  a{
    margin-left: 0.5rem;
    text-decoration: none;
    color: inherit;
  }
`
export const ContentWargos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: grey;
  margin-bottom: 2rem;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
`

export const CustomCheckbox = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin:1rem 0px 0.5rem;
  label{
    font-size:14px;
  }
`