import React from 'react'

const Notificaciones = () => {
  return (
    <div>
      notificaciones
    </div>
  )
}

export default Notificaciones
