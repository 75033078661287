import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 3px;
}

*::-webkit-scrollbar-track {
  background: #e1e5eb;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #531C99 !important;
  width: 16px !important;
  transition: 0.2s all ease-in-out;
  border-radius: 4px !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 20px !important;
}
`

export const dreamsColor = {
  homeBackground: 'linear-gradient(45deg, rgb(50 0 200 / 70%), rgb(0 100 255 / 70%))',
  shadowBorderColor:'rgb(0 150 255)',
  backgroundPosition:'center bottom',

  itemBackground: 'rgb(0 41 144 / 50%)',
  item:'rgb(25 7 73 / 30%)',
  itemSelect:'rgb(25 7 73 / 60%)',

  tableHead:'rgb(15 12 105 / 80%)',
  tableItem:'rgb(44 60 197 / 45%)',

  btnBackground:'linear-gradient(180deg, #005ef2, #001d89)',
  btnShadow:'#011048',
  btnPush:'linear-gradient(180deg,#003c9b,#010b32)',

  scrollbar:'#5a51f8',
  scrollbarThumb:'#019bff'
}

export const generalColor = {
  homeBackground: 'rgb(120 0 0 / 90%)',
  shadowBorderColor: 'rgb(255 0 0)',
  backgroundPosition:'center top',

  itemBackground: 'rgb(140 0 0 / 20%)',
  item:'rgb(240 0 0 / 30%)',
  itemSelect:'rgb(240 0 0 / 60%)',

  tableHead:'rgba(105, 12, 12, 0.43)',
  tableItem:'rgb(77 7 7 / 45%)',
  
  btnBackground:'linear-gradient(180deg, #d27806, #87500a)',
  btnShadow:'#220100',
  btnPush:'linear-gradient(180deg,#915406,#503006)',

  scrollbar:'#5a1212',
  scrollbarThumb:'#b8860b'
};