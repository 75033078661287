import React from 'react'

const Puntos = () => {
  return (
    <div>
      puntos
    </div>
  )
}

export default Puntos
