import React,{ useState, useEffect } from 'react'
import { IconAnfora, IconCupones, IconPromociones, IconRecompensas, IconResumen, IconSorteos, LogoLink, SidebarLabel, SidebarLink, SidebarLogo, SidebarNav, SidebarWrap } from './sidebarElements'

const Sidebar = () => {

  const [sala, setSala] = useState([]);

  useEffect(() => {
    let checkSala = localStorage.getItem("nombreSala")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
    }
  }, [])

  return (
    <>
      <SidebarNav>
        <SidebarLogo>
          <LogoLink to='/main'>
            <img src={sala.path} alt='Casino' height='50px'/>
          </LogoLink>
        </SidebarLogo>
        <SidebarWrap>
          {/* <SidebarLink to='/main' exact={true}>
            <IconResumen />
            <SidebarLabel>Resumen</SidebarLabel>
          </SidebarLink> */}
          <SidebarLink to='/main' exact={true}>
            <IconCupones />
            <SidebarLabel>Cupones</SidebarLabel>
          </SidebarLink>
          {/* <SidebarLink to='/main/puntos'>
            <IconPuntos />
            <SidebarLabel>Puntos</SidebarLabel>
          </SidebarLink> */}
          <SidebarLink to='/main/anforas'>
            <IconAnfora />
            <SidebarLabel>Ánforas</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/main/sorteos'>
            <IconSorteos />
            <SidebarLabel>Sorteos</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/main/recompensas'>
            <IconRecompensas />
            <SidebarLabel>Recompensas</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/main/promociones'>
            <IconPromociones />
            <SidebarLabel>Promociones</SidebarLabel>
          </SidebarLink>
          {/* <SidebarLink to='/main/notificaciones'>
            <IconNotificaciones />
            <SidebarLabel>Notificaciones</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/main/contactanos'>
            <IconContactanos />
            <SidebarLabel>Contactanos</SidebarLabel>
          </SidebarLink> */}
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default Sidebar