import React,{ useState, useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Sorteo, { IconSorteosSecondary } from '../components/templates/sorteoTemplate'
import CabeceraTemplate from '../components/templates/cabeceraTemplate'
import LoadingCard from '../components/generales/loading'
import Noelements from '../components/generales/noelements'

const Sorteos = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))

  const {data , loading} = useFetch(Apis.SORTEOS + '/sorteos?salaId=' + salaInfo.salaId)

  const [sorteosdelDia, setSorteosdelDia] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setSorteosdelDia(data)
    }
  }, [data])

  return (
    <>
      {loading ? 
        <LoadingCard />
        : 
        <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12} >
              <CabeceraTemplate nombreitem='sorteos' presentacion='sorteos para el dia de hoy:'
              icono={ <IconSorteosSecondary style={{width:'40px', height:'40px'}} />} 
              cantidad={sorteosdelDia.length} />
            </Col>
          </Row>
            
          <Row  style={{marginTop:'2rem'}} >
            {sorteosdelDia.length > 0 ?
              <>
              <Col>
                <Sorteo datasorteo={sorteosdelDia}/>
              </Col>
              </>
              :
              <Col  xs={12} sm={12} md={12}>
                <Noelements titulo='No hay sorteos disponibles para el día de hoy' subtitulo='Vuelva a intentarlo más tarde' />
              </Col>
            }
          </Row> 
        </Container>
      }
    </>
  )
}

export default Sorteos
