import axios from "axios";
import {Apis} from '../../util/Apis'

let instance = axios.create({
  baseURL: Apis.OAUTH,
  //responseType: "json",
  //headers: { 'Authorization': 'Bearer '+localStorage.getItem('auth') }
});

export default instance
