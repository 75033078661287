import React,{ useState, useEffect } from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cupon from '../components/templates/cuponTemplate'
import CabeceraTemplate from '../components/templates/cabeceraTemplate'

import puntos from '../components/icons/puntos.svg'
import cupones from '../components/icons/cupones.svg'
import LoadingCard from '../components/generales/loading'
import Noelements from '../components/generales/noelements'

const IconCupones = styled.div`
  width:30px;
  height:32px;
  background-color: #0A1033;
  -webkit-mask: url(${cupones}) no-repeat center;
  mask: url(${cupones}) no-repeat center;
`

const IconPuntos = styled.div`
  width:30px;
  height:30px;
  background-color: #0A1033;
  -webkit-mask: url(${puntos}) no-repeat center;
  mask: url(${puntos}) no-repeat center;
`

export const TituloCategoria = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #0A1033; 
  span{
    margin-right:0.5rem;
  }
`

const Resumen = () => {

  const salaInfo = JSON.parse(localStorage.getItem('nombreSala'))

  const {data: dataCoupon, loading: loadingCoupon} = useFetch(Apis.CAPP + '/point-coupons?salaId=' + salaInfo.salaId)

  const [puntosCliente, setPuntosCliente] = useState(0)
  const [cuponesCliente, setCuponesCliente] = useState([])

  useEffect(() => {
    if (dataCoupon !== undefined && dataCoupon !== null) {
      setPuntosCliente(dataCoupon.points)
      setCuponesCliente(dataCoupon.coupons)
    }
  }, [dataCoupon])

  return (
    <>
      {loadingCoupon ? 
        <LoadingCard />
        : 
        <Container fluid style={{marginTop:'-4rem',padding:'0 1rem'}} >
          <Row>
            <Col xs={12} sm={12} md={12} >
              <CabeceraTemplate nombreitem='Puntos' presentacion='actualmente tienes:'
              icono={ <IconPuntos style={{width:'40px', height:'40px'}} />} 
              cantidad={Math.floor(puntosCliente)} />
            </Col>
          </Row>

          <Row className='my-3'>
            <Col>
              <TituloCategoria>
                <span>Cupones </span>
                <IconCupones />
              </TituloCategoria>
            </Col>
          </Row>

          <Row>
            {cuponesCliente.length > 0 ?
              <>
                {cuponesCliente.map((item, i) =>
                  <Col  xs={12} sm={6} md={6} key={i}>
                    <Cupon nombre={item.couponName} cantidad={item.available} porcentaje={item.percentage} cuponColor={'#198754'}/>
                  </Col>
                )}
              </>
              :
              <Col  xs={12} sm={12} md={12}>
                <Noelements titulo='No hay cupones disponibles en este momento' subtitulo='Vuelva a intentarlo más tarde' />
              </Col>
            }
          </Row>

        </Container>
      }
    </>
  )
}

export default Resumen
